<template>
  <div class="change-department w-100 d-flex flex-column justify-center mb-8">
    <div class="handover-select w-100">
      <label for="handover">{{ $t('saveIssue.handover') }}</label>
      <v-select
        v-model="department"
        id="handover"
        :items="departmentTypes"
        item-text="description"
        item-value="id"
        outlined
        required
        validate-on-blur
        dense
        single-line
        :disabled="closed || !$permission('IssuePush')"
      />
    </div>
    <div class="actions d-flex">
      <v-btn
        color="primary"
        :loading="moveToDepartmentLoading"
        :disabled="moveToDepartmentLoading"
        @click="moveToDepartmentClick"
      >
        {{ $t("saveIssue.sign") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Service from '@/services'
import { departmentEnum } from '@/utils/helpers'

export default {
  name: 'ChangeDepartment',
  props: {
    value: {
      type: Number,
      required: true
    },
    register: {
      type: Boolean,
      default: false
    },
    closed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      moveToDepartmentLoading: false,
      department: null
    }
  },
  computed: {
    departmentTypes () {
      return this.$store.getters.enums.departmentTypes
    }
  },
  created () {
    this.department = this.value
  },
  methods: {
    async moveToDepartmentClick () {
      this.moveToDepartmentLoading = true
      try {
        await Service.post('Issue/MoveIssueToAnotherDepartment', {
          issueId: this.$route.params.id,
          targetDeparment: this.department
        })

        const { data: currentUserDepartment } = await Service.get('Issue/GetUserDepartment')

        if (currentUserDepartment !== departmentEnum.customerService) {
          if (this.register) {
            await this.$router.push({ name: 'CompaniesIssuesCustomerService', params: { activeTab: '1' } })
          } else {
            await this.$router.push({ name: 'IssuesCustomerService', params: { activeTab: '1' } })
          }
        }

        this.$emit('department-changed', this.department)

        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('saveIssue.issueHandoveredTo')}
           ${this.$options.filters.getDescription(this.department, this.departmentTypes)}`
        )
      } catch (e) {
      } finally {
        this.moveToDepartmentLoading = false
      }
    }
  }
}
</script>
