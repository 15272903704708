<template>
  <div class="change-status">
    <template v-if="!loading">
      <v-row no-gutters>
        <v-col cols="12">
          <label for="bokAssign">{{ $t('saveIssue.bokAssign') }}</label>
          <v-select
            v-model="form.customerService.currentlyHandledBy"
            id="bokAssign"
            :items="usersToAssign"
            item-text="name"
            item-value="id"
            return-object
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="disabledCustomerServiceState"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-between mb-2">
          <v-btn
            color="primary"
            :disabled="disabledCustomerServiceState || !form.customerService.currentlyHandledBy"
            @click="showSignUserDialog(form.customerService.currentlyHandledBy)"
          >
            {{ $t("saveIssue.sign") }}
          </v-btn>
          <v-btn
            v-if="showResignButtonForCustomerService"
            color="primary"
            @click="showResignUserDialog"
          >
            {{ $t("saveIssue.resign") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <label for="bokStatus">{{ $t('saveIssue.bokStatus') }}</label>
          <v-select
            v-model="form.customerService.state"
            id="bokStatus"
            :items="issueStates"
            item-value="id"
            item-text="description"
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="disabledCustomerServiceState"
            @change="updateStatusChange"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="companyId">
        <v-col cols="12">
          <label for="bokAssign">{{ $t('saveIssue.salesAssign') }}</label>
          <v-select
            v-model="form.salesHandledBy"
            id="salesAssign"
            :items="salesUsersToAssign"
            item-text="name"
            item-value="id"
            return-object
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="!$permission('AssigningSalesperson')"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-between mb-2">
          <v-btn
            color="primary"
            :disabled="!$permission('AssigningSalesperson') || !form.salesHandledBy"
            @click="showSignSalesUserDialog(form.salesHandledBy)"
          >
            {{ $t("saveIssue.sign") }}
          </v-btn>
          <v-btn
            v-if="form.salesHandledBy"
            color="primary"
            @click="showResignSalesUserDialog"
          >
            {{ $t("saveIssue.resign") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-8">
        <v-col cols="12">
          <label for="vindicationAssign">{{ $t('saveIssue.vindicationAssign') }}</label>
          <v-select
            v-model="form.debtRecovery.currentlyHandledBy"
            id="vindicationAssign"
            :items="usersToAssign"
            item-text="name"
            item-value="id"
            outlined
            required
            validate-on-blur
            dense
            single-line
            return-object
            :disabled="disabledDebtRecoveryState"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-between mb-2"
        >
          <v-btn
            color="primary"
            :disabled="disabledDebtRecoveryState || !form.debtRecovery.currentlyHandledBy"
            @click="showSignUserDialog(form.debtRecovery.currentlyHandledBy)"
          >
            {{ $t("saveIssue.sign") }}
          </v-btn>
          <v-btn
            v-if="showResignButtonForDebtRecovery"
            color="primary"
            @click="showResignUserDialog"
          >
            {{ $t("saveIssue.resign") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <label for="vindicationStatus">{{ $t('saveIssue.vindicationStatus') }}</label>
          <v-select
            v-model="form.debtRecovery.state"
            id="vindicationStatus"
            :items="issueStates"
            item-value="id"
            item-text="description"
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="disabledDebtRecoveryState"
            @change="updateStatusChange"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-8">
        <v-col cols="12">
          <label for="legalDepartmentAssign">{{ $t('saveIssue.legalDepartmentAssign') }}</label>
          <v-select
            v-model="form.legalDepartment.currentlyHandledBy"
            id="legalDepartmentAssign"
            :items="usersToAssign"
            item-text="name"
            item-value="id"
            outlined
            required
            validate-on-blur
            dense
            single-line
            return-object
            :disabled="disabledLegalDepartmentState"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-between mb-2">
          <v-btn
            color="primary"
            :disabled="disabledLegalDepartmentState || !form.legalDepartment.currentlyHandledBy"
            @click="showSignUserDialog(form.legalDepartment.currentlyHandledBy)"
          >
            {{ $t("saveIssue.sign") }}
          </v-btn>
          <v-btn
            v-if="showResignButtonForLegalDepartment"
            color="primary"
            @click="showResignUserDialog()"
          >
            {{ $t("saveIssue.resign") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <label for="legalDepartmentStatus">{{ $t('saveIssue.legalDepartmentStatus') }}</label>
          <v-select
            v-model="form.legalDepartment.state"
            id="legalDepartmentStatus"
            :items="issueStates"
            item-value="id"
            item-text="description"
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="disabledLegalDepartmentState"
            @change="updateStatusChange"
          ></v-select>
        </v-col>
      </v-row>
    </template>
    <ConfirmDialog
      v-model="resignUserDialog"
      :title="resignUserDialogTitle"
      @confirm="resignUser()"
      @close="closeResignUserDialog()"/>
    <ConfirmDialog
      v-model="signUserDialog"
      :title="$t('saveIssue.signDialogTitle')"
      @confirm="updateUserChange"
      @close="closeSignUserDialog"/>
    <ConfirmDialog
      v-model="signSalesUserDialog"
      :title="$t('saveIssue.signDialogTitle')"
      @confirm="updateSalesUserChange"
      @close="closeSignUserDialog"/>
    <ConfirmDialog
      v-model="resignSalesUserDialog"
      :title="resignUserDialogTitle"
      @confirm="resignSalesUser()"
      @close="closeResignSalesUserDialog()"/>
  </div>
</template>

<script>
import Service from '@/services'
import { departmentEnum } from '@/utils/helpers'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: 'ChangeState',
  components: { ConfirmDialog },
  props: {
    issueState: {
      type: Object
    },
    companyId: null,
    department: {
      type: Number
    },
    closed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      usersToAssign: null,
      salesUsersToAssign: null,
      currentUserDepartment: null,
      resignUserDialog: false,
      resignSalesUserDialog: false,
      signUserDialog: false,
      signSalesUserDialog: false,
      selectedUser: null
    }
  },
  computed: {
    form: {
      get () {
        return this.issueState
      },
      set () {}
    },
    resignUserDialogTitle () {
      return this.$t('saveIssue.resignDialogTitle')
    },
    issueStates () {
      return this.$store.getters.enums.issueState
    },
    disabledCustomerServiceState () {
      return this.currentUserDepartment !== departmentEnum.customerService ||
        this.department !== departmentEnum.customerService ||
        this.closed
    },
    disabledDebtRecoveryState () {
      return this.currentUserDepartment !== departmentEnum.debtRecovery ||
        this.department !== departmentEnum.debtRecovery ||
        this.closed
    },
    disabledLegalDepartmentState () {
      return this.currentUserDepartment !== departmentEnum.legalDepartment ||
        this.department !== departmentEnum.legalDepartment ||
        this.closed
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    showResignButtonForCustomerService () {
      return this.showResignButtonForDepartment(this.form.customerService, departmentEnum.customerService)
    },
    showResignButtonForDebtRecovery () {
      return this.showResignButtonForDepartment(this.form.debtRecovery, departmentEnum.debtRecovery)
    },
    showResignButtonForLegalDepartment () {
      return this.showResignButtonForDepartment(this.form.legalDepartment, departmentEnum.legalDepartment)
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data: usersToAssign } = await Service.get('Issue/GetUsersToAssign')
        this.usersToAssign = usersToAssign
        const { data: currentUserDepartment } = await Service.get('Issue/GetUserDepartment')
        this.currentUserDepartment = currentUserDepartment
        const { data: salesUsersToAssign } = await Service.get('User/GetSalesUsers')
        this.salesUsersToAssign = salesUsersToAssign
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async updateUserChange () {
      try {
        await Service.post('Issue/ResignIssue', { issueId: this.$route.params.id })
        await Service.post('Issue/AssignIssue', {
          issueId: this.$route.params.id,
          userId: this.selectedUser.id
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.signUserDialog = false
      } catch (e) {
      } finally {
        this.selectedUser = null
      }
    },
    async updateSalesUserChange () {
      try {
        await Service.post('CustomerService/AssignSalesman', {
          companyId: this.companyId,
          userId: this.selectedUser.id
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.signSalesUserDialog = false
      } catch (e) {
      } finally {
        this.selectedUser = null
      }
    },
    async resignSalesUser () {
      try {
        await Service.post('CustomerService/AssignSalesman', {
          companyId: this.companyId,
          userId: null
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.resignSalesUserDialog = false
        this.form.salesHandledBy = null
      } catch (e) {
      } finally {
        this.selectedUser = null
      }
    },
    async resignUser () {
      try {
        await Service.post('Issue/ResignIssue', {
          issueId: this.$route.params.id
        })
        this.$emit('user-resigned')

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } catch (ex) {} finally {
        this.resignUserDialog = false
      }
    },
    async updateStatusChange (targetStatus) {
      try {
        await Service.post('Issue/ChangeIssueStatus', {
          issueId: this.$route.params.id,
          targetStatus
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } catch (e) {}
    },
    showResignButtonForDepartment (formDepartment, enumDepartment) {
      return this.department === enumDepartment &&
        formDepartment.currentlyHandledBy &&
        !this.closed &&
        (formDepartment.currentlyHandledBy.id === this.currentUserId ||
          this.$permission('AssignIssueToUser'))
    },
    showResignUserDialog () {
      this.resignUserDialog = true
    },
    closeResignUserDialog () {
      this.resignUserDialog = false
    },
    showSignUserDialog (user) {
      this.selectedUser = user
      this.signUserDialog = true
    },
    closeSignUserDialog () {
      this.selectedUser = null
      this.signSalesUserDialog = false
    },
    showSignSalesUserDialog (user) {
      this.selectedUser = user
      this.signSalesUserDialog = true
    },
    closeSignSalesUserDialog () {
      this.selectedUser = null
      this.signSalesUserDialog = false
    },
    showResignSalesUserDialog () {
      this.resignSalesUserDialog = true
    },
    closeResignSalesUserDialog () {
      this.resignSalesUserDialog = false
    }
  }
}
</script>
